.navbar {
  width: 100%;
  padding: 15px 15px;
  height: 70px;
  // background: #1290fc;
  border-bottom: 1px solid rgb(192, 192, 192);
  
  > div {
    flex: 1 0 auto;
    height: 100%;
    gap: 25px;
  }

  .right {
    flex: 1 0 0;
    max-width: fit-content;
    margin-left: auto;

    .round {
      border-radius: 30px;
      padding: 5px;
      border: 1px solid rgb(228, 228, 228);
    }
  }

  svg {
    color: #0091FF;
  }

  .logo {
    height: 70px;
    width: 180px;
  }

  .but {
    cursor: pointer;
    
    p {
      font-size: 10px;
    }
  }

  .input-wrapper {
    position: relative;
    height: 100%;
    max-width: 600px;
    width: 100%;

    svg {
      position: absolute;
      right: 6px;
      top: 8px;
    }

    input {
      height: 100%;
      outline: none;
      border: none;
      box-shadow: 0 0 5px 1px rgb(201, 201, 201);
      border-radius: 4px;
      width: 100%;
    }
  }
}
