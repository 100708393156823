@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.css-wop1k0-MuiDataGrid-footerContainer {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

a {
  color: unset;
  text-decoration: none;
}

div {

  &.flex {
    display: flex;
  }
  &.apart {
    justify-content: space-between;
  }
  &.col {
    flex-direction: column;
  }
  &.center {
    align-items: center;
  }
  &.ndcenter {
    justify-content: center;
  }
  &.w100 {
    width: 100%;
  }
}