.footer {
  background: rgb(196, 196, 196);
  padding: 4px 10px;
  
  > div {
    gap: 15px;

  }

  a, p {
    font-size: 10px;
  }
}