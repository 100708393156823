
.menu-box {
  left: 35px;
  margin-right: 35px;
  position: relative;
  background: white;
  border-radius: 16px 0 0 0;
  border: 1px solid #A1B1BC;
  box-sizing: border-box;
  height: 100%;
  z-index: 1002;
  //overflow-y: auto;

  // > *:nth-child(1) {
  //   display: flex;
  //   flex-direction: column;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  // }
  .menu-content {
    flex: 1 0 0px;
    width: 100%;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 5px;
  };
  &::-webkit-scrollbar-track {
    background: white;
  };

  &::-webkit-scrollbar-thumb {
    background: #888;
  };

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  padding-bottom: 10px;
  background: white;
}


.outer-menu {
  position: absolute;
  top: 15%;
  right: 0;
  height: 85%;
  width: 800px;
  z-index: 1002;
  overflow: hidden;
  &.ignore {
    pointer-events: none;
  }
}


.inner-menu {
  right: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition: right 0.4s;
  transition-timing-function: ease-out
}


.outer-image {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px 0 0 8px;
  position: absolute;
  background-color: rgb(240, 240, 240);
  padding: 10px 20px 10px 10px;
  border: 1px solid #A1B1BC;
  z-index: 1001;
}


.close-btn {
  transition: transform 0.4s;
  transition-timing-function: ease-out;
}


.closed-menu {
  right: calc(-100% + 35px);
  pointer-events: all;

  #closeBtn {
    transform: rotate(180deg)
  }
}

.menu-box {
  padding: 15px;
}

.step {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .panel-header {
    color: #0091FF;
    font-size: 24px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(206, 206, 206);
    font-weight: 600;
  }

  .description {
    text-align: center;
  }

  .controls {
    gap: 15px;
    margin-top: auto;
    
    .back {
      cursor: pointer;
      // height: 100%;
      border-radius: 6px;
      border: 1px solid rgb(206, 206, 206);
      height: 55px;
      width: 80px;
  
      svg {
        width: 60px;
        height: 60px;
        color: #1290fc;
      }
    }
  }
}

.check-panel {
  justify-content: space-between;

  .controls {
    margin-top: 0;
  }

  .description {
    font-weight: 500;
    font-size: 20px;

    > p:nth-child(2) {
      margin-top: 40px;
    }
  }
}

.calculating-panel {
  
  .calculating {
    align-self: center;
    margin: auto 0;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 60px;

    p {
      margin-top: 20px;
    }
  }

}

.finished-panel {
  img {
    width: 300px;
  }

}
